<template>
  <div class="content-box">
    <div class="content">
      <div class="policylib-result express">
        <el-tabs v-model="activeName">
          <el-tab-pane label="我发起的需求列表" name="first">
            <!-- 筛选 -->
            <div class="filters-list">
              <ul class="filters">
                <li
                  class="fitler-item"
                  v-for="(fitler, goodsIndex) in fitlerList"
                  :key="goodsIndex"
                >
                  <span class="nameFrame">{{ fitler.title }}</span>
                  <ul class="cates">
                    <li
                      :class="{
                        active: typeIndex === fitler.index,
                      }"
                      v-for="(type, typeIndex) in fitler.typeList"
                      :key="typeIndex"
                      @click="handleclick(typeIndex, fitler, type, goodsIndex)"
                    >
                      {{ type.name }}
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- 已经选择： -->
              <ul class="filters">
                <li class="fitler-item">
                  <span class="nameFrame">已经选择：</span>
                  <ul class="cates">
                    <li
                      class="filtered-item"
                      v-for="(sureItem, index) in FilterList"
                      :key="index"
                    >
                      {{ sureItem }}
                      <i class="el-icon-close" @click="minItemClick(index)"></i>
                    </li>

                    <li class="clear-all-filtered" @click="resetClick">
                      清除筛选
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 表格 -->
            <div class="table">
              <div class="header">
                <span
                  :class="idx == index ? 'contrast' : ''"
                  @click="Csort(i, idx)"
                  v-for="(i, idx) in sort"
                  :key="idx"
                >
                  {{ i }}
                </span>
               </div>
              <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="需求名称" align="center">
                  <template slot-scope="scope">
                    <p @click="handleXq(scope.$index, scope.row)">
                      {{ scope.row.title }}
                    </p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="created_at"
                  label="发布时间"
                  
                  align="center"
                  
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.created_at">{{ scope.row.created_at.substring(0, 10) }}</span>
                    <span v-else></span>
                    
                  </template> </el-table-column
                ><el-table-column
                  prop="territory.name"
                  label="所属领域"
                
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address.title"
                  label="所在地区"
                 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="is_grant"
                  label="审核状态"
              
                  align="center"
                >
                  <!-- <template slot-scope="scope">
            <span v-if="scope.row.is_grant === 1" style="color: #eb9810"
              >通过</span
            >
            <span v-else-if="scope.row.is_grant === 2" style="color: #1ed44c"
              >拒绝</span
            >
            <span v-else-if="scope.row.is_grant === 3" style="color: #e00f0f"
              >待审核</span
            >
          </template> -->
                  <template slot-scope="{ row }">
                    {{ ["", "通过", "拒绝", "待审核"][row.is_grant] }}
                  </template>
                </el-table-column>
                <el-table-column label="服务状态" width="180" align="center">
                  <template slot-scope="{ row }">
                    {{ ["", "待服务", "服务中", "服务结束"][row.service] }}
                  </template>
                </el-table-column>
              </el-table>

              <div class="pagination">
                <pagination
                  style="padding: 0"
                  class="pages"
                  v-show="total > 0"
                  :total="total"
                  layout="total,  prev, pager, next, jumper"
                  :page.sync="listQuery.page"
                  :limit.sync="listQuery.limit"
                  @pagination="getdemandList"
                />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 弹出框 -->
      <!-- 详情 -->
      <el-dialog
        class="dialog"
        :visible.sync="dia.dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <Xq :item="dia"></Xq>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { DemandList, is_grant, fwzt, Ares, demand } from "@/common/js/api";
import Pagination from "@/common/Pagination";
import Xq from "./xq/test1-1";
export default {
  components: { Pagination, Xq },
  data() {
    return {
      activeName: "first",
      fitlerList: [
        {
          title: "领域分类：",
          typeList: [],
        },
        {
          title: "地区分类：",
          typeList: [],
        },
        {
          title: "审核状态：",
          typeList: [],
        },
        {
          title: "服务进度：",
          typeList: [],
        },
      ], //筛选
      FilterList: { 0: "全部", 1: "全部", 2: "全部", 3: "全部" }, //已经选择数据
      FilterListId: {}, //已经选择数据id
      sort: ["综合排序", "发布时间"],
      index: 0,
      tableData: [],
      total: 0, //总条目数
      listQuery: {
        limit: 10,
        page: 1,
      },
      dia: {
        dialogVisible: false,
        id: "",
        isshow: true,
      },
      resource: "",
      all: 1,
      created_at: "",
    };
  },
  created() {
    this.fitlerList.forEach((item) => this.$set(item, "index", 0));
    this.getdemandList();
    this.getfwzt();
    this.getisgrant();
    this.getAres();
    this.getDemand();
  },
  methods: {
    // 详情
    handleXq(index, row) {
      this.dia.dialogVisible = true;
      this.dia.id = row.id;
    },
    // 列表
    getdemandList() {
      this.getList(this.FilterListId);
    },

    // 筛选
    handleclick(typeIndex, goods, type, goodsIndex) {
      goods.index = typeIndex;
      this.$set(this.FilterList, goodsIndex, type.name);
      this.$set(this.FilterListId, goodsIndex, type.id);
      this.getList(this.FilterListId);
    },
    // 已选择每项的x键
    minItemClick(minIndex) {
      this.$delete(this.FilterList, minIndex);
      this.$delete(this.FilterListId, minIndex);
      this.fitlerList[minIndex].index = 0;
      this.getList(this.FilterListId);
    },
    /* 清除筛选 */
    resetClick() {
      for (var i in this.fitlerList) {
        this.fitlerList[i].index = 0;
      }
      this.FilterList = { 0: "全部", 1: "全部", 2: "全部", 3: "全部" };
      this.FilterListId = {};
      this.getList(this.FilterListId);
     },
    getList(IdList) {
       DemandList({
        member_id: JSON.parse(localStorage.getItem("user")).id,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
        territory: IdList[0],
        address: IdList[1],
        is_grant: IdList[2],
        service: IdList[3],
        all: this.all,
        created_at: this.created_at,
      })
        .then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    // 排序
    Csort(item, idx) {
      this.index = idx;
      console.log(this.index);
      if (this.index == 0) {
        this.all = 1;
        this.created_at = "";
      } else if (this.index == 1) {
        this.all = "";
        this.created_at = 2;
      }
      this.getList(this.FilterListId);
    },
    // 领域
    getDemand() {
      demand()
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.fitlerList[0].typeList = res.data;
        })
        .catch(() => {});
    },
    // 地区
    getAres() {
      Ares()
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]["name"] = res.data[i].title;
          }
          var b = { name: "全部" };
          res.data.unshift(b);
          this.fitlerList[1].typeList = res.data;
        })
        .catch(() => {});
    },
    // 服务状态
    getfwzt() {
      fwzt()
        .then((res) => {
          // res.data[0] = "全部";

          var arr = [];
          for (let i in res.data) {
            arr.push({ id: i, name: res.data[i] });
          }
          var b = { name: "全部" };
          arr.unshift(b);
          this.fitlerList[3].typeList = arr;
        })
        .catch(() => {});
    },
    // 审核状态
    getisgrant() {
      is_grant()
        .then((res) => {
          // res.data[0] = "全部";
          var arr = [];
          for (let i in res.data) {
            arr.push({ id: i, name: res.data[i] });
          }
          var b = { name: "全部" };
          arr.unshift(b);
          this.fitlerList[2].typeList = arr;
        })
        .catch(() => {});
    },
  },
};
</script>
 

 <style scoped src="@/assets/css/page.css"></style>
<style lang="less" scoped>
.el-input-group {
  width: 603px;
  border-radius: 10px;
}
/deep/.el-input-group__append {
  background: #3892eb;
  color: #fff;
}
/deep/.el-tabs__header {
  padding: 0 43px;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
/deep/.el-pager li,
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background-color: #f7f4f4;
}
.el-table__row:hover p {
  color: #3892eb;
  cursor: pointer;
}
// 弹出框
.dialog {
  /deep/.el-dialog__body {
    padding: 10px 60px 50px;
  }
}
</style>